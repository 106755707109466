.App {
  height: 100%;
  text-align: center;
  background-image: url("./img/white-background.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
button {
  border: 1px solid #000;
  border-radius: 3px;
}

button:hover {
  transform: translateY(1px);
  border: 1px solid blue;
}
.App .container-box {
  min-height: 100vh;
}
@media only screen and (max-width: 480px) {
  .App input[type="radio"] {
    width: 1.1rem;
    height: 1.1rem;
  }
}
