.footer {
  width: 100vw;
  background-color: darkgrey;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #000;
  margin-top: 3rem;
}
.copy-right {
  font-size: 0.9rem;
  color: #fff;
  font-weight: 600;
}
