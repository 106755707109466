.welcome {
  max-width: 80vw;
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 1rem;
  margin: 0 auto;
}
.welcome p {
  font-size: 18px;
  font-weight: 550;
  text-transform: uppercase;
}
.welcome input {
  position: relative;
  min-width: 14rem;
  min-height: 1.5rem;
  padding: 0.25rem;
  padding-left: 0.5rem;
  border: 0.5px solid #000;
  outline: 0.5px solid #000;
  font-size: 16px;
  border-radius: 3px;
}
.welcome input:focus {
  outline: 0.5px solid #000;
  border: 0.5px solid #000;
}

.welcome button {
  min-width: 10rem;
  min-height: 1.2rem;
  padding: 0.5rem;
  font-size: 16px;
  letter-spacing: 3px;
  outline: 0.75px solid #000;
}

button.hide-show {
  min-width: 2rem;
  font-size: 18px;
  min-height: 1rem;
  padding: 0;
  border: none;
  outline: none;
  letter-spacing: 1px;
  background-color: transparent;
}
.box-show-hide {
  position: relative;
}
.show-hide-btn {
  position: absolute;
  width: fit-content;
  top: 7px;
  right: 7px;
}
.box-modal span.text-md {
  text-transform: uppercase;
}
.box-modal a {
  cursor: pointer;
  color: #000;
  font-weight: 600;
}
