.header {
  background-image: url("../../img/white-background.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  padding: 0.5rem 0;
  border-bottom: 1px solid darkgrey;
}
.container-ef {
  display: flex;
  justify-content: center;
  max-width: 100vw;
  height: 3rem;
  margin: 0 auto;
  align-items: center;
}
.text {
  position: relative;
  font-size: 1em;
  background: transparent;
  color: #000;
  text-transform: uppercase;
  letter-spacing: 0.3em;
  font-weight: bold;
  padding: 5px;
}
.text span {
  color: #fff;
  mix-blend-mode: difference;
}
.text:before {
  content: "";
  position: absolute;
  top: 0;
  width: 60px;
  height: 100%;
  background: #000;
  animation: thangcover 4s linear infinite;
}
@keyframes thangcover {
  0% {
    left: 0;
  }
  50% {
    left: calc(100% - 60px);
  }
  100% {
    left: 0;
  }
}
